import { Image } from "react-bootstrap";
import css from './style.module.scss'

type Props = {
  backgroundURL: string
  logoURL: string
}

const RBImage = (props: Props) => {
  const { backgroundURL, logoURL} = props

  return (
    <div
      style={{ backgroundImage: `url(${backgroundURL})` }}
      className={`text-center ${css.backgroundImage}`}
    >
      {logoURL &&
        <Image
          roundedCircle={true}
          thumbnail={true}
          src={logoURL}
          width={150}
          height={150}
          style={{ border: '3px solid #000' }}
          className='mt-2'
        />
      }
    </div>
  )
}

export default RBImage;
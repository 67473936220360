/* eslint-disable import/no-anonymous-default-export */
import ListGroup from 'react-bootstrap/ListGroup'
import ListGroupItem from 'react-bootstrap/ListGroupItem'
import Card from 'react-bootstrap/Card'
import Accordion from 'react-bootstrap/Accordion'
import AccordionItem from "../../../../../components/AccordionItem"
import css from "./style.module.scss"

type Props = {
  title: string
  data?: any
}

export default function(props: Props) {
  const { data } = props

  return (
    <>
      {data && <>
        {data?.orgLinks &&
          <Accordion className={css.accordion}>
            <AccordionItem
              eventKey='0'
              header={`Total of ${data?.orgLinks.length} ORG Links`}
            >
              <ListGroup className="list-group-flush">
                {data?.orgLinks?.map((link: any) => (
                  <ListGroupItem id={`org-${link.id}`}>
                    <Card.Link href={`https://www.linkedin.com/company/${link.id}`}>
                      {link.name || link.id}
                    </Card.Link>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </AccordionItem>
          </Accordion>
        }

        {data?.orgLinks &&
          <Accordion className={css.accordion}>
            <AccordionItem
              eventKey='1'
              header={`Total of ${data?.perLinks.length} Person Links`}
            >
              <ListGroup className="list-group-flush">
                {data?.perLinks?.map((link: any) => (
                  <ListGroupItem id={`per-${link.id}`}>
                    <Card.Link href={`https://www.linkedin.com/in/${link.id}`}>
                      {link.name || link.id}
                    </Card.Link>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </AccordionItem>
          </Accordion>
        }
      </>}
    </>
  )
}

// LinkedIn Data Template
import LinkedInCompany from "./components/LinkedInCompany";
import LinkedInPerson from "./components/LinkedInPerson";
import LinkedInSocialIDs from "./components/LinkedInSocialIDs";
import LinkedInSalesPeople from "./components/LinkedInSalesPeople";
import LinkedInSalesCompany from "./components/LinkedInSalesCompany";
import Card from 'react-bootstrap/Card'

import { currentPage } from "../../../utilities";

import css from './../../App/style.module.scss'

type Props = {
  entryURL: string
  title: string
  data?: any
}

export default function LinkedIn(props: Props) {
  const { entryURL, data } = props

  return (
    <Card className={css.card}>
      <Card.Body className={css.cardBody}>
        {
          (currentPage(entryURL).isLinkedinCompany || currentPage(entryURL).isLinkedinOrganization) &&
          <LinkedInCompany title="Company" data={data} />
        }
        {
          (currentPage(entryURL).isLinkedinPerson || currentPage(entryURL).isLinkedinSalesPersonPage) &&
          <LinkedInPerson title="Person" data={data} />
        }
        {
          currentPage(entryURL).isLinkedinSalesPeoplePage &&
          <LinkedInSalesPeople title="Sales People" data={data} />
        }
        {
          currentPage(entryURL).isLinkedinSalesCompany &&
          <LinkedInSalesCompany title="Sales Company" data={data} />
        }
        {
          currentPage(entryURL).isLinkedinDomain &&
          <LinkedInSocialIDs title="Social IDs" data={data} />
        }
      </Card.Body>
    </Card>
  )
}

/* eslint-disable react/jsx-no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";

import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Image from "react-bootstrap/Image";
import Alert from "../../../components/Alert"

// get our fontawesome imports (solid/regular)
import { faFileDownload, faCopy, faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import logo from "./../../../resources/icon48.png";
import css from "./style.module.scss"

type Props = {
  enabled: boolean
  title?: string
  data?: any
}

const RNNavbar = (props: Props) => {
  const { title, data, enabled } = props

  const [jsonData, setJsonData] = useState<any>({})
  const [fileName, setFileName] = useState<string>(`${title}.json`)

  useEffect(() => {
    setJsonData(JSON.stringify(data, undefined, 2))
    setFileName(`${title}-${data?.name || Date.now()}.json`)
  }, [data])

  const copyToClipboard = () => {
    // Write text to the clipboard, or "copy"
  }

  const exportToJSON = () => {
    let vLink = document.createElement('a')
    const vBlob = new Blob([jsonData], { type: "octet/stream" })
    vLink.setAttribute('href', window.URL.createObjectURL(vBlob))
    vLink.setAttribute('download', fileName)
    vLink.click()
  }

  return (
    <>
      <Navbar collapseOnSelect bg="dark" variant="dark" fixed="top" className={css.bgColor}>
        <Container className={css.bgColor}>
          <Navbar.Brand className={css.brand} >
            {/* <FontAwesomeIcon icon={faHome} className={css.whiteColor} />
            {' '} */}
            {title}
          </Navbar.Brand>
          <Nav>
            <Nav.Link href='https://ranbot.online' className={`${css.whiteColor} ${css.bigSize}`} target='_blank'>
              <Image
                roundedCircle={true}
                thumbnail={true}
                src={logo}
                width={30}
                height={30}
                className='mt-1'
              />
            </Nav.Link>
          </Nav>
          {enabled && <Nav>
            <Nav.Link className={css.actionButton}>
              <Alert
                type='success'
                text={<FontAwesomeIcon icon={faCopy} className={css.whiteColor} />}
                headline={title}
                message="Data copied to clipboard."
                onClick={copyToClipboard} />
            </Nav.Link>
            <Nav.Link className={css.actionButton}>
              <Alert
                type='info'
                text={<FontAwesomeIcon icon={faFileDownload} className={css.whiteColor} />}
                headline={title}
                message="Export to JSON file."
                onClick={exportToJSON} />
            </Nav.Link>
          </Nav>}
        </Container>
      </Navbar>
    </>
  )
}

export default RNNavbar;
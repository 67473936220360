/* eslint-disable import/no-anonymous-default-export */
import styled, { keyframes } from 'styled-components';
import { fadeIn, fadeOut } from 'react-animations';

const fadeInAnimation = keyframes`${fadeIn}`;
const fadeOutAnimation = keyframes`${fadeOut}`;

const fadeInDiv = styled.div`
  animation: .2s ${fadeInAnimation};
`;

const fadeOutDiv = styled.div`
  animation: .2s ${fadeOutAnimation};
`;

export default {
  fadeInDiv,
  fadeOutDiv
}
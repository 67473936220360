/* eslint-disable import/no-anonymous-default-export */
import logo from "./../../../resources/icon48.png";

import Card from 'react-bootstrap/Card'
import Image from "react-bootstrap/Image";

import css from './style.module.scss'

export default function() {
  return (
    <Card.Footer className={css.cardFooter}>
      <a
        href="https://chrome.google.com/webstore/detail/linkedin-viewer/danbjaaihgdhofdcdlbhpdljchcogped"
        target="_blank"
        rel="noreferrer"
        className={css.cardFooterLink}
      >
        <Image
          roundedCircle={true}
          thumbnail={true}
          src={logo}
          width={25}
          height={25}
        />

        <strong>
          LinkedIn Viewer
          <em className="ranbotExtVersion" />
        </strong>
      </a>
    </Card.Footer>
  )
}
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from "react";
import { Alert, AlertContainer } from "react-bs-notifier";

import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.min.js"
import { Tooltip } from 'bootstrap/dist/js/bootstrap.esm.min.js'

type Props = {
  type: string
  text: string | JSX.Element
  message: string
  headline?: string
  onClick?: (() => void) | undefined;
}

const RNAlert = (props: Props) => {
  const { headline, text, message, type, onClick } = props
	const [showing, setShowing] = React.useState({
		info: false,
		success: false,
		warning: false,
		danger: false
	});

	useEffect(() => {
		// Init tooltip
		Array.from(
			document.querySelectorAll('button[data-bs-toggle="tooltip"]')
		).forEach(tooltipNode => new Tooltip(tooltipNode))
	});

	const onAlertToggle = useCallback((type) =>
    setShowing(s => ({ ...s, [type]: !s[type] })),
  	[]
	);

	const combinedClicks = (callback: any) => {
		onAlertToggle(type)
		callback && callback()
		setTimeout(function() { onAlertToggle(type) }, 2000);
	}

	return (
		<div>
			<AlertContainer position="top-right">
        {["info", "success", "warning", "danger"].map(name => (
          type === name && showing[type] &&
          <Alert type={name} headline={headline} timeout={1}>
						{message}
					</Alert>
				))}
			</AlertContainer>

			<div className="btn-group">
        {["info", "success", "warning", "danger"].map(name => (
          type === name &&
          <button
            className={`btn btn-${name}`}
						data-bs-toggle="tooltip"
						data-bs-placement="top"
						title={message}
            onClick={() => combinedClicks(onClick)}
          >
            {text}
          </button>
				))}
			</div>
		</div>
	);
}

export default RNAlert;

/* eslint-disable import/no-anonymous-default-export */
import Accordion from "react-bootstrap/Accordion"
import css from "./style.module.scss"

type Props = {
  header: string | JSX.Element
  eventKey: string
  children?: any
}

export default function(props: Props) {
  const {
    eventKey,
    header,
    children
  } = props

  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header className={css.accordionHeader}>
        {header}
      </Accordion.Header>
      <Accordion.Body className={css.accordionBody}>
        {children}
      </Accordion.Body>
    </Accordion.Item>
  )
}

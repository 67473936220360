/* eslint-disable react/jsx-no-undef */
/* eslint-disable import/no-anonymous-default-export */
import { useEffect, useState } from 'react'

import Table from 'react-bootstrap/Table'
import Accordion from 'react-bootstrap/Accordion'
import AccordionItem from '../../../../../components/AccordionItem'
import ListGroup from 'react-bootstrap/ListGroup'
import Badge from 'react-bootstrap/Badge'

import css from './style.module.scss'
import RBImage from '../../../../../components/Image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink } from "@fortawesome/free-solid-svg-icons";

type Props = {
  title: string
  data?: any
}

export default function(props: Props) {
  const { data } = props
  const [employeesLength, setEmployeesLength] = useState<Number>(0)

  useEffect(() => {
    setEmployeesLength(data.monthlyEmployeeCounts?.length || 0)
  }, [data])

  const employeesGrowths = () => {
    return (
      ` (6M: ${data.insightEmployee6MGrowth} / 1YR: ${data.insightEmployee1YRGrowth} / 2YR: ${data.insightEmployee2YRGrowth})`
    )
  }

  return (
    <>
      <Table striped bordered hover className={css.dataTable}>
        <tbody>
          {data.logoURL && <tr>
            <td colSpan={2}>
              <RBImage backgroundURL={data.backgroundURL} logoURL={data.logoURL} />
            </td>
          </tr>}
          <tr>
            <td>Name</td>
            <td className={css.dataValue}>{data.name}</td>
          </tr>
          {data.numericID &&
            <tr>
              <td>Numeric ID</td>
              <td className={css.dataValue}>{data.numericID}</td>
            </tr>
          }
          <tr>
            <td>Alpha ID</td>
            <td className={css.dataValue}>
              {data.alphaID}
            </td>
          </tr>
          {data.cbPermalink && <tr>
            <td>CB Permalink</td>
            <td className={css.dataValue}>
              {data.website && <>
                {data.cbPermalink}

                <a href={data.cbLink} style={{ marginLeft: '5px' }}>
                  <FontAwesomeIcon icon={faLink} />
                </a>
              </>}
            </td>
          </tr>}
          <tr>
            <td>Website</td>
            <td className={css.dataValue}>
              {data.website && <>
                {data.website}

                <a href={data.website} style={{ marginLeft: '5px' }}>
                  <FontAwesomeIcon icon={faLink} />
                </a>
              </>}
            </td>
          </tr>
          <tr>
            <td>Industry</td>
            <td className={css.dataValue}>{data.industry}</td>
          </tr>
          <tr>
            <td>Headquarters</td>
            <td className={css.dataValue}>{data.headquarters}</td>
          </tr>
          <tr>
            <td>Size</td>
            <td className={css.dataValue}>{data.companySize}</td>
          </tr>
          <tr>
            <td>Employees</td>
            <td className={css.dataValue}>
              {data.insightEmployeeCount || data.employees}
              {data.insightEmployee6MGrowth && employeesGrowths()}
            </td>
          </tr>
          {data.stockSymbol && <tr>
            <td>Stock</td>
            <td className={css.dataValue}>
              {data.stockSymbol} - {data.stockMarket} - {data.stockPrice}
            </td>
          </tr>}
          <tr>
            <td>Is Public</td>
            <td className={css.dataValue}>
              {data.companyType === 'Public' ? 'Yes' : null}
            </td>
          </tr>
          <tr>
            <td>Followers</td>
            <td className={css.dataValue}>{data.followers}</td>
          </tr>
          {data.founded && <tr>
            <td>Founded</td>
            <td className={css.dataValue}>{data.founded}</td>
          </tr>}
          {data.phone &&
          <tr>
            <td>Phone</td>
            <td className={css.dataValue}>{data.phone}</td>
          </tr>}
          {data.specialties &&
          <tr>
            <td>Specialties</td>
            <td className={css.dataValue}>{data.specialties}</td>
          </tr>}
          <tr>
            <td>Overview</td>
            <td className={css.dataValue}>{data.overview}</td>
          </tr>
        </tbody>
      </Table>

      {data?.monthlyEmployeeCounts && employeesLength > 0 &&
        <Accordion className={css.accordion} defaultActiveKey='0' alwaysOpen>
          <AccordionItem
            eventKey='0'
            header={`Insights Employees (${employeesLength})`}
          >
            <ListGroup className="list-group-flush">
              {data?.monthlyEmployeeCounts?.map((item: any) => (
                <ListGroup.Item
                  className="d-flex justify-content-between align-items-start mt-1"
                  variant="primary"
                >
                <div className="ms-2 me-auto">
                  <div className="fw-bold">
                    {item.date}
                  </div>
                </div>

                <Badge bg="info" pill>
                  {item.count}
                </Badge>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </AccordionItem>
        </Accordion>
      }
    </>
  )
}

import { StrictMode } from "react";
import { createRoot } from 'react-dom/client';
import App from './containers/App';
import reportWebVitals from './reportWebVitals';

import './index.scss';

const container = document.getElementById('RBOTExt');
const root = createRoot(container);
let entryURL = window.location.href
let data = {}

window.addEventListener('message', (event: any) => {
  if (event.origin.startsWith('chrome-extension') && event.data) {
    entryURL = event.data?.url || window.location.href
    data = event.data?.data
  }

  root.render(
    <StrictMode>
      <App title='RanBOT' entryURL={entryURL} data={data} />
    </StrictMode>
  );
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

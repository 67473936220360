/* eslint-disable @typescript-eslint/no-unused-vars */
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import Container from "react-bootstrap/Container";

import css from "./style.module.scss"

export default function NoContent(props: any) {
  return (
    <Container className="pt-5">
      <Card className={`${css.card} mt-4`}>
        <Card.Header as="h6" className={`${css.cardHeader}`}>LinkedIn Data Scaper</Card.Header>
        <Card.Body>
          <Card.Text className={css.cardText}>
            Easily view normalized data and quickly copy or export LinkedIn data. {" "}
            <Card.Link
              href="https://www.linkedin.com"
              color="blue"
              target="_blank"
            >
              Try it!
            </Card.Link>
          </Card.Text>

          <hr />
          <Card.Text className={css.cardText}>
            This tool will automatically collect data for you to our data center
            when you viewing the LinkedIn page for your convenience in later use.
          </Card.Text>

          <hr />
          <Button
            className={`${css.button}`}
            href="https://ranbot.online"
						title='RanBOT'
            target="_blank"
            variant="info"
          >
            View RanBOT
          </Button>
        </Card.Body>

        <Card.Body>
          <a href="https://www.vultr.com/?ref=8991180-8H" target='_blank' rel="noreferrer">
            <div className={css.vultr}>
              Sign Up to Get Free $35 Worth Credits on Vultr
            </div>
          </a>
        </Card.Body>

        <Card.Header as="h6" className={css.cardHeader}>LinkedIn Pages</Card.Header>
        <Card.Body>
          <ListGroup>
            <ListGroup.Item className={css.listGroupItem}>Public Company Detail Page</ListGroup.Item>
            <ListGroup.Item className={css.listGroupItem}>Company Detail Page</ListGroup.Item>
            <ListGroup.Item className={css.listGroupItem}>People Detail Page</ListGroup.Item>
            <ListGroup.Item className={css.listGroupItem}>Sales Navigator - Company Detail Page</ListGroup.Item>
            <ListGroup.Item className={css.listGroupItem}>Sales Navigator - Insights Page</ListGroup.Item>
            <ListGroup.Item className={css.listGroupItem}>Sales Navigator - People Page</ListGroup.Item>
            <ListGroup.Item className={css.listGroupItem}>Sales Navigator - People Lists Page</ListGroup.Item>
          </ListGroup>
        </Card.Body>
      </Card>
    </Container>
  )
}
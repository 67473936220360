/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable import/no-anonymous-default-export */
import Accordion from "react-bootstrap/Accordion"
import Table from "react-bootstrap/Table"
import ListGroup from "react-bootstrap/ListGroup"
import Badge from "react-bootstrap/Badge"
import AccordionItem from "../../../../../components/AccordionItem"
import RBImage from "../../../../../components/Image"

import css from './style.module.scss'

import { useState, useEffect } from "react"

type Props = {
  title: string
  data?: any
}

export default function(props: Props) {
  const { data } = props
  const [employeesCount, setEmployeesCount] = useState<number>(0)

  useEffect(() => {
    setEmployeesCount(data.monthlyEmployeeCounts.length)
  }, [data])

  const employeesGrowths = () => {
    return (
      <>
        (6M: {data.insightEmployee6MGrowth} / 1YR: {data.insightEmployee1YRGrowth} / 2YR: {data.insightEmployee2YRGrowth})
      </>
    )
  }

  return (
    <>
      <Table striped bordered hover className={css.dataTable}>
        <tbody>
          <tr>
            <td colSpan={2}>
              <RBImage backgroundURL={data.backgroundURL} logoURL={data.logoURL} />
            </td>
          </tr>
          <tr>
            <td>Name</td>
            <td className={css.dataValue}>{data.name}</td>
          </tr>
          <tr>
            <td>Numeric ID</td>
            <td className={css.dataValue}>{data.numericID}</td>
          </tr>
          {data.alphaID && <tr>
            <td>Alpha ID</td>
            <td className={css.dataValue}>{data.alphaID}</td>
          </tr>}
          <tr>
            <td>Industry</td>
            <td className={css.dataValue}>{data.industry}</td>
          </tr>
          <tr>
            <td>Employees</td>
            <td className={css.dataValue}>
              {data.insightEmployeeCount || data.employees}
              {data.insightEmployee6MGrowth && employeesGrowths()}
            </td>
          </tr>
          <tr>
            <td>Revenue</td>
            <td className={css.dataValue}>{data.revenue}</td>
          </tr>
          <tr>
            <td>Location</td>
            <td className={css.dataValue}>{data.location}</td>
          </tr>
          <tr>
            <td>Type</td>
            <td className={css.dataValue}>{data.companyType}</td>
          </tr>
          <tr>
            <td>Website</td>
            <td className={css.dataValue}>{data.website}</td>
          </tr>
          <tr>
            <td>Founded</td>
            <td className={css.dataValue}>{data.founded}</td>
          </tr>
          <tr>
            <td>Specialties</td>
            <td className={`${css.dataValue}`}>{data.specialties}</td>
          </tr>
          <tr>
            <td>Overview</td>
            <td className={`${css.dataValue}`}>{data.overview}</td>
          </tr>
        </tbody>
      </Table>

      {data?.monthlyEmployeeCounts && employeesCount > 0 &&
        <Accordion className={css.accordion} defaultActiveKey='0' alwaysOpen>
          <AccordionItem
            eventKey='0'
            header={`Insights Employees (${employeesCount})`}
          >
            <ListGroup className="list-group-flush">
              {data?.monthlyEmployeeCounts?.map((item: any) => (
                <ListGroup.Item
                className="d-flex justify-content-between align-items-start mt-1"
                variant="primary"
                >
                <div className="ms-2 me-auto">
                  <div className="fw-bold">
                    {item.date}
                  </div>
                </div>

                <Badge bg="info" pill>
                  {item.count}
                </Badge>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </AccordionItem>
        </Accordion>
      }
    </>
  )
}

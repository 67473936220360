/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react-hooks/exhaustive-deps */
// import * as AssetsService from "./../../../services/assets-service";
import { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import Card from 'react-bootstrap/Card'
import Spinner from "react-bootstrap/Spinner";
import Container from "react-bootstrap/Container";

// Pages
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import LinkedIn from "../pages/LinkedIn";
import About from "../pages/About";

// get our fontawesome imports (solid/regular)
// import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import css from './style.module.scss'

type Props = {
  title: string,
  entryURL: string,
  data: any
}

function App(props: Props) {
  const { title, entryURL, data } = props
  const [pageData, setPageData] = useState<boolean>(false)

  useEffect(() => {
    setPageData(Object.keys(data).length > 0)
  }, [data])

  return (
    <div className={css.ranBotApp}>
      <Navbar enabled={pageData} data={data} title={title} />

      <BrowserRouter>
        {/* <Routes>
          <Route path="/" element={
            <Container className="pt-2">
              <Card className={`${css.card} text-center`}>
                <Card.Body className={css.cardBody}>
                  <Spinner animation="border" role="status" variant="info" className={css.spinnerBorder}>
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </Card.Body>
              </Card>
            </Container>
          }/>
          <Route path="/about" element={<About />}/>
          <Route path="/linkedin" element={<LinkedIn title={title} data={data} />}/>
          <Route path="/redirect" element={<Navigate to="/about" />}/>
        </Routes> */}

        {
          entryURL === undefined &&
          <Container className="pt-2">
            <Card className={`${css.card} text-center`}>
              <Card.Body className={css.cardBody}>
                <Spinner animation="border" role="status" variant="info" className={css.spinnerBorder}>
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </Card.Body>
            </Card>
          </Container>
        }

        {
          entryURL.match(/linkedin.com\//) ?
            <LinkedIn title={title} data={data} entryURL={entryURL} /> :
            <About />
        }
      </BrowserRouter>

      <Footer />
    </div>
  );
}

export default App;

/* eslint-disable no-loop-func */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable import/no-anonymous-default-export */
import Accordion from 'react-bootstrap/Accordion'
import ListGroup from 'react-bootstrap/ListGroup'
import Nav from 'react-bootstrap/Nav'
import Image from 'react-bootstrap/Image'
import AccordionItem from "../../../../../components/AccordionItem"

import css from "./style.module.scss"
import "../../../../../components/Animations"

import { useState, useEffect } from 'react'
import { onlyUnique } from '../../../../../utilities'

type Props = {
  title: string
  data?: any
}

export default function(props: Props) {
  const [companyName, setcompanyName] = useState<any>(null);
  const { data } = props
  // const orgNames = new RegExp('\\b(' + (data?.orgNames || []).join("|") + ')\\b', 'gi')

  useEffect(() => {
    let names = data?.items?.map((item: any) => item.companyName)?.filter(onlyUnique)?.join(', ')

    setcompanyName(names)
  }, [data])

  // const scrapingPeople = () => {
    // let links = data?.items?.map((item: any) => item.link) || []

    // while (links.length > 0) {
    //   const pageLink = links[0]

    //   chrome.runtime.sendMessage({ name: "openTabInWindow", url: pageLink }, () => {
    //     links = links.filter((e: string) => { return e !== pageLink })

    //     sleep(3000)
    //   })
    // }

    // for (let index = 0; index < dataItems.length; index++) {
    //   const item = dataItems[index]

    //   await chrome.runtime.sendMessage({ name: "openTabInWindow", url: item.link })
    //   await sleep(3000)
    // }

    // for (const item of dataItems) {
    //   const pageURL = await item.link
    //   console.log(`pageURL: ${pageURL}`)
    //   await chrome.runtime.sendMessage({ name: "openTabInWindow", url: item.link })
    //   await sleep(3000)
    // }
  // }

  return (
    <>
      {data?.items && data.totalCount > 0 &&
        <Accordion className={css.accordion} defaultActiveKey='0' alwaysOpen>
          <AccordionItem
            eventKey='0'
            header={`Total of ${data.totalCount} Sales People - (${companyName})`}
          >
            <ListGroup className="list-group-flush">
              {data?.items?.map((item: any) => (
                <ListGroup.Item
                  className="d-flex justify-content-between align-items-start mt-2"
                >
                  <div className="ms-2 me-auto" style={{ width: '70%'}}>
                    <div className="fw-bold">
                      <h3>
                        <a href={item.link} target='_blank' rel="noreferrer">{item.name}</a>
                      </h3>
                    </div>

                    <hr />
                    <div>
                      {item.jobTitle}
                      <strong style={{ display: 'inline-block' }}>
                        <Nav.Link href={item.companyLink}>{item.companyName}</Nav.Link>
                      </strong>
                    </div>
                    <address>{item.location}</address>
                  </div>

                  <Image roundedCircle={true} thumbnail={true} src={item.avatar} className="mt-3"/>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </AccordionItem>
        </Accordion>
      }
    </>
  )
}

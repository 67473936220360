/* eslint-disable import/no-anonymous-default-export */
import Table from 'react-bootstrap/Table'
import Card from 'react-bootstrap/Card'
import Image from 'react-bootstrap/Image'
import Button from 'react-bootstrap/Button'
import Accordion from 'react-bootstrap/Accordion'
import AccordionItem from "../../../../../components/AccordionItem"
import RBImage from '../../../../../components/Image'

import css from './style.module.scss'

type Props = {
  title: string
  data?: any
}

export default function(props: Props) {
  const { data } = props
  const colors = ['primary', 'secondary', 'success', 'warning', 'danger', 'info', 'light', 'dark']

  return (
    <>
      <Table striped bordered hover className={css.dataTable}>
        <tbody>
          <tr>
            <td colSpan={2}>
              <RBImage backgroundURL={data.backgroundURL} logoURL={data.logoURL} />
            </td>
          </tr>
          <tr>
            <td>Name</td>
            <td className={css.dataValue}>{data.name}</td>
          </tr>
          <tr>
            <td>Position</td>
            <td className={css.dataValue}>{data.position}</td>
          </tr>
          <tr>
            <td>Alpha ID</td>
            <td className={css.dataValue}>{data.identifier}</td>
          </tr>
          <tr>
            <td>Email</td>
            <td className={css.dataValue}>{data.email}</td>
          </tr>
          <tr>
            <td>location</td>
            <td className={css.dataValue}>{data.location}</td>
          </tr>
          <tr>
            <td>Followers</td>
            <td className={css.dataValue}>{data.followers}</td>
          </tr>
          <tr>
            <td>Overview</td>
            <td className={css.dataValue}>{data.overview}</td>
          </tr>
        </tbody>
      </Table>

      {data?.experiences &&
        <Accordion defaultActiveKey={Array.from(Array(data?.experiences.length).keys()).map(x => `${x}`)} alwaysOpen>
          {data?.experiences?.map((item, index) => (
            <AccordionItem
              eventKey={`${index}`}
              header={
                <>
                  <Image
                    roundedCircle={true}
                    thumbnail={true}
                    src={item.company?.logoURL}
                    width='40px'
                    height='40px'
                    className={css.marginRight}
                  />
                  {' '}
                  {item.occupation}
                </>
              }
            >
              <Card className={css.card}>
                <Card.Link href={item.company?.url}>{item.company?.name}</Card.Link>
                <Card.Text className={css.cardText}>
                  {item.from} to {item.to} - {item.location}

                  {item.description && <>
                    <hr />
                    {item.description}
                  </>}
                </Card.Text>
              </Card>
            </AccordionItem>
          ))}
        </Accordion>
      }

      {data?.educations &&
        <Accordion defaultActiveKey={Array.from(Array(data?.educations.length).keys()).map(x => `${x}`)} alwaysOpen>
          {data?.educations?.map((item, index) => (
            <AccordionItem
              eventKey={`${index}`}
              header={
                <>
                  <Image
                    roundedCircle={true}
                    thumbnail={true}
                    src={item.education?.logoURL}
                    width='40px'
                    height='40px'
                    className={css.marginRight}
                  />
                  {' '}
                  {item.education?.name}
                </>
              }
            >
              <Card className={css.card}>
                <Card.Text className={css.cardText}>
                  {[item.degree, item.study].join(' ')}

                  {item.dates && <>
                    <hr />
                    {item.dates}
                  </>}
                </Card.Text>
              </Card>
            </AccordionItem>
          ))}
        </Accordion>
      }

      {data?.skills &&
        <Accordion defaultActiveKey='0' alwaysOpen>
          <AccordionItem
            eventKey='0'
            header='Skills'
          >
            <Card className={css.card}>
              <Card.Text className={css.cardText}>
                {data?.skills?.map((item, index) => (
                  <>
                    <Button variant={`outline-${
                      colors[Math.floor(Math.random() * colors.length)]
                    }`} size='lg' className={css.skillButton}>
                      {item.name}
                    </Button>
                    {' '}
                  </>
                ))}
              </Card.Text>
            </Card>
          </AccordionItem>
        </Accordion>
      }
    </>
  )
}
/* eslint-disable no-useless-escape */
import {
  LINKEDIN_SITE_REG,
  LINKEDIN_PERSON_REG,
  LINKEDIN_COMPANY_REG,
  LINKEDIN_ORGANIZATION_REG,
  LINKEDIN_SALES_COMPANY_REG,
  LINKEDIN_SALES_PERSON_REG,
  LINKEDIN_SALES_PEOPLE_REG,
  LINKEDIN_SCHOOL_REG,
  CRUNCHBASE_SITE_REG,
  CRUNCHBASE_COMPANY_REG
} from "./constants"

export const currentPage = (url = window.location.href) => {
  const isLinkedinDomain = LINKEDIN_SITE_REG.test(url)
  const isLinkedinPerson = LINKEDIN_PERSON_REG.test(url)
  const isLinkedinCompany = LINKEDIN_COMPANY_REG.test(url)
  const isLinkedinOrganization = LINKEDIN_ORGANIZATION_REG.test(url)
  const isLinkedinSalesCompany = LINKEDIN_SALES_COMPANY_REG.test(url)
  const isLinkedinSalesPersonPage = LINKEDIN_SALES_PERSON_REG.test(url)
  const isLinkedinSalesPeoplePage = LINKEDIN_SALES_PEOPLE_REG.test(url)
  const isLinkedinSchoolPage = LINKEDIN_SCHOOL_REG.test(url)
  const isCrunchbaseDomain = CRUNCHBASE_SITE_REG.test(url)
  const isCrunchbaseCompany = CRUNCHBASE_COMPANY_REG.test(url)

  return {
    URL: url,
    isLinkedinDomain,
    isLinkedinCompany,
    isLinkedinOrganization,
    isLinkedinSalesCompany,
    isLinkedinSalesPersonPage,
    isLinkedinPerson,
    isLinkedinSalesPeoplePage,
    isLinkedinSchoolPage,
    isCrunchbaseDomain,
    isCrunchbaseCompany
  }
}

export const linkedInIDMatcher = (url: string) => {
  let linkedinID
  if (currentPage(url).isLinkedinDomain) {
    linkedinID =
      url.match(/companyId=(\d+)/)?.[1] ||
      url.match(/(recruiter|sales)\/company\/(\d+)/)?.[2] ||
      url.match(
        /.com\/(company|showcase|school|companies|company-beta|organization|organization-guest\/company)\/((\w|\-|\&|\.)+)/
      )?.[2]
  }

  return linkedinID
}

export const linkedInPersonIDMatcher = (url: string) => {
  let linkedinPersonID
  if (currentPage(url).isLinkedinDomain) {
    linkedinPersonID = url.match(
      /\/(in|sales\/people)\/((\w|\-|\&|\.|\%)+)/
    )?.[2]
  }

  return linkedinPersonID
}

export const crunchbaseIDMatcher = (url: string) => {
  let crunchbaseID
  if (currentPage(url).isCrunchbaseCompany) {
    crunchbaseID = url.match(
      /\/(organization)\/((\w|\-|\&|\.|\%)+)/
    )?.[2]
  }

  return crunchbaseID
}

export const socialIDMatchers = {
  linkedin: linkedInIDMatcher,
  crunchbase: crunchbaseIDMatcher,
  linkedinPerson: linkedInPersonIDMatcher
}

export const onlyUnique = (value: any, index: any, self: any) :boolean => {
  return self.indexOf(value) === index;
}

export const sleep = async (ms: number) => new Promise(r => setTimeout(r, ms))

export const getCleanText = (text: string | null | undefined): any => {
  const regexRemoveMultipleSpaces = / +/g
  const regexRemoveLineBreaks = /(\r\n\t|\n|\r\t)/gm

  if (!text) return null

  const cleanText = text
    .replace(regexRemoveLineBreaks, '')
    .replace(regexRemoveMultipleSpaces, ' ')
    .replace('...', '')
    .replace(/See more/i, '')
    .replace(/See less/i, '')
    .trim()

  return cleanText
}

export const getHostname = (url: string) => {
  return new URL(url).hostname;
};
/* eslint-disable no-useless-escape */
export const LINKEDIN_SITE_REG = /^https?\:\/\/(www\.)?linkedin\.com/
export const LINKEDIN_SALES_COMPANY_REG = /^https?\:\/\/(www\.)?linkedin\.com\/sales\/company/
export const LINKEDIN_SEARCH_COMPANY_REG = /^https\:\/\/www\.linkedin\.com\/(search\/results\/people)\/\?currentCompany=\[\"(\d+)\"\]/
export const LINKEDIN_SALES_PERSON_REG = /^https?\:\/\/(www\.)?linkedin\.com\/sales\/people/
export const LINKEDIN_SALES_PEOPLE_REG = /^https?\:\/\/(www\.)?linkedin\.com\/sales\/search\/people/
export const LINKEDIN_PERSON_REG = /https\:\/\/www\.linkedin\.com\/in\/([^\/]+)\//
export const LINKEDIN_SCHOOL_REG = /https\:\/\/www\.linkedin\.com\/(school)\/((\w|\-|\&|\.)+)/
export const LINKEDIN_ORGANIZATION_REG = /https\:\/\/www\.linkedin\.com\/(organization-guest\/company)\/((\w|\-|\&|\.)+)/
export const LINKEDIN_COMPANY_REG = /https\:\/\/www\.linkedin\.com\/(company|companies|company-beta|showcase|school)\/((\w|\-|\&|\.)+)/
export const CRUNCHBASE_SITE_REG = /^https?\:\/\/(www\.)?crunchbase\.com/
export const CRUNCHBASE_COMPANY_REG = /^https?\:\/\/www\.crunchbase\.com\/(organization)\/((\w|\-|\&|\.)+)/
export const DELAY_TIME = 2000